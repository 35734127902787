import React from "react"

import books from "./books.png"
import bookshelf from "./bookshelf.png"
import logoPremium from "./logoPremium.svg"
import starIcon from "./star.svg"
import AppDownloadButtons from "~/components/AppDownloadButtons/AppDownloadButtons"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { FormattedHeading } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"

import css from "./Premium.module.scss"

const AccountPremium = (props: TemplateDefaultPropTypes) => {
  const isCompact = useIsOnCompact()

  return isCompact ? (
    <div className={css.rootMobile}>
      <div className={css.starContainer}>
        <ColorableSVG
          href={starIcon}
          color="white"
          width={48}
          height={48}
          style={{ top: "0px", left: "20px" }}
        />
        <ColorableSVG
          href={starIcon}
          color="white"
          width={31}
          height={31}
          style={{ top: "99px", left: "312px" }}
        />
        <ColorableSVG
          href={starIcon}
          color="white"
          width={20}
          height={20}
          style={{ top: "96px", left: "0px" }}
        />
        <ColorableSVG
          href={starIcon}
          color="white"
          width={20}
          height={20}
          style={{ top: "83px", left: "280px" }}
        />
      </div>
      <ColorableSVG href={logoPremium} color="black" width={138} height={47} />
      <FormattedHeading translationId="premium/title" variant="medium" />
      <div className={css.storesContainer}>
        <AppDownloadButtons height={48} />
      </div>
      <img src={bookshelf} className={css.bookshelf} />
    </div>
  ) : (
    <div>
      <div className={css.root}>
        <div className={css.starContainer}>
          <ColorableSVG
            href={starIcon}
            color="white"
            width={48}
            height={48}
            style={{ top: "0px", left: "0px" }}
          />
          <ColorableSVG
            href={starIcon}
            color="white"
            width={48}
            height={48}
            style={{ top: "200px", left: "24px" }}
          />
          <ColorableSVG
            href={starIcon}
            color="white"
            width={48}
            height={48}
            style={{ top: "166px", left: "716px" }}
          />
          <ColorableSVG
            href={starIcon}
            color="white"
            width={29}
            height={29}
            style={{ top: "71px", left: "35px" }}
          />
          <ColorableSVG
            href={starIcon}
            color="white"
            width={29}
            height={29}
            style={{ top: "31px", left: "687px" }}
          />
        </div>
        <ColorableSVG
          href={logoPremium}
          color="black"
          width={209}
          height={71}
        />
        <FormattedHeading translationId="premium/title" variant="medium" />
        <div className={css.storesContainer}>
          <AppDownloadButtons height={50} />
        </div>
      </div>
      <div className={css.booksContainer}>
        <img src={books} />
      </div>
    </div>
  )
}

export default withTemplate(AccountPremium)
